import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Modal, Select, Table, Button, Col, Row, Input, Tooltip, Form, Flex, InputNumber } from 'antd';
import { LoadingOutlined, ReloadOutlined, PrinterOutlined, SwapOutlined, DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import { notification } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { usePDF } from '@react-pdf/renderer';
import printJS from 'print-js';

import Toolbar from '@controls/toolbar/toolbar';
import FormHeader from '@controls/form-header/form-header';
import PrintProcessing from '@controls/print-processing';

import { exception, securityRestriction, error } from '@extensions/notification';
import { userLoaded } from '@store/actions';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import { serverFetch } from '@src/core/server';
import BarcodePdf from '@print-forms/barcode-pdf';

import { IConsignmentLabel } from '@entities/consignment-label';
import { IBox } from '@entities/box';
import { IBoxGroup } from '@entities/box-group';
import { IMarking } from '@entities/marking';
import { IUserSession } from '@entities/user-session';
import { ITruck } from '@entities/truck';
import { ITruckFilter } from '@entities/truck-filter';
import { ITransferBox } from 'transfer-box';
import { IRemainingBox } from '@entities/remaining-box';

import { Permission, hasPermission } from '@enums/permission';
import { TruckType } from '@enums/truck-type';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

interface IDeleteBoxParams {
    id: string;
    reason: string;
}

const Remaining = () => {
    const { TextArea } = Input;

    const [transferForm] = Form.useForm();
    const [deleteBoxForm] = Form.useForm();

    const { boxGroupId } = useParams();
    const [api, contextHolder] = notification.useNotification();
    const [modal, modalContextHolder] = Modal.useModal();

    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const d = useAppDispatch();

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [boxGroup, setBoxGroup] = useState<IBoxGroup>();
    const [boxes, setBoxes] = useState<Array<IBox>>();
    const [loading, setLoading] = useState<boolean>(false);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);
    const [openTransfer, setOpenTransfer] = useState<boolean>(false);

    const [trucks, setTrucks] = useState<Array<ITruck>>([]);
    const [markings, setMarkings] = useState<Array<IMarking>>([]);
    const [printData, setPrintData] = useState<IConsignmentLabel | undefined>();
    const [startPrint, setStartPrint] = useState<boolean>(false);

    const [openDeleteBoxDialog, setOpenDeleteBoxDialog] = useState<boolean>(false);

    const [pdfInstance, updatePdf] = usePDF();

    const truckFilter: ITruckFilter = {
        isArchived: false,
        type: TruckType.Import,
    };

    useEffect(() => {
        let cleanup = false;

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch('markings', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения маркировок', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('trucks/list', { method: 'GET', queryParams: truckFilter })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения машин', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            await onLoadBoxes();

            Promise.all([promises]).then((result) => {
                if (cleanup) return;
                setMarkings(result[0][0]);
                setTrucks(result[0][1]);

                setLoading(false);
            });
        };

        fetchData();
        return () => {
            cleanup = true;
        };
    }, []);

    useEffect(() => {
        if (!refreshRequired) return;

        setRefreshRequired(false);
        onLoadBoxes();
    }, [refreshRequired]);

    useEffect(() => {
        if (printData) {
            updatePdf(<BarcodePdf labels={printData} />);
            setStartPrint(true);
        }
    }, [printData]);

    useEffect(() => {
        if (startPrint && !pdfInstance.loading && pdfInstance.blob) {
            setStartPrint(false);
            setPrintData(undefined);

            const blobURL = URL.createObjectURL(pdfInstance.blob);
            printJS(blobURL);
        }
    }, [startPrint, pdfInstance]);

    const onLoadBoxes = async () => {
        setLoading(true);

        serverFetch(`remainings/${boxGroupId}/boxes`, { method: 'GET' })
            .then((data: Array<IRemainingBox>) => {
                setBoxes(data as Array<IBox>);

                let remainingBox: IRemainingBox = data[0];
                let item: IBoxGroup = {
                    id: remainingBox.boxGroupId,
                    consignmentId: undefined,
                    itemId: undefined,
                    qty: undefined,
                    consigneeCode: remainingBox.consigneeCode,
                    countryId: remainingBox.countryId,
                    countryName: remainingBox.countryName,
                    truckId: remainingBox.truckId,
                    markingId: remainingBox.markingId,
                    markingCode: remainingBox.markingCode,
                    comment: remainingBox.boxGroupComment,
                    canTransfer: remainingBox.canTransfer,
                    awbNumber: remainingBox.awbNumber,
                };

                if (item) {
                    setBoxGroup(item);
                }

                transferForm.setFieldValue('markingId', item.markingId);
                transferForm.setFieldValue('countryId', item.countryId);
                transferForm.setFieldValue('truckId', item.truckId);

                setLoading(false);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка получения деталей груза', ex, () => d(userLoaded(undefined)));
            });
    };

    const getPrintData = (ids: Array<string>) => {
        serverFetch(`warehouse/box/print`, { method: 'POST', bodyData: ids })
            .then((data) => {
                setPrintData(data);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения груза', ex, () => d(userLoaded(undefined)));
            });
    };

    const onTransfer = (entity: ITransferBox) => {
        if (!boxGroup) return;

        entity.boxIds = selectedRowKeys as Array<string>;

        serverFetch(`remainings/transfer`, { method: 'POST', bodyData: entity })
            .then(() => {
                transferForm.resetFields();
                setRefreshRequired(true);
                setOpenTransfer(false);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка перемещения коробок', ex, () => d(userLoaded(undefined)));
            });
    };

    const onDeleteBox = (data: IDeleteBoxParams) => {
        setLoading(true);

        serverFetch(`warehouse/box/${data.id}`, { method: 'DELETE', bodyData: data.reason })
            .then(() => {
                deleteBoxForm.resetFields();
                setOpenDeleteBoxDialog(false);

                setRefreshRequired(true);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка удаления коробки', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Перенести на другую маркировку',
                        key: 'transfer',
                        disabled: selectedRowKeys.length <= 0,
                        icon: <SwapOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.TransferBoxes)) {
                                securityRestriction(api, [Permission.TransferBoxes]);
                                return;
                            }

                            if (!boxGroup?.canTransfer) {
                                error(api, 'Нельзя переместить коробки пока не завершится приемка партии');
                                return;
                            }

                            setOpenTransfer(true);
                        },
                    },
                    {
                        label: 'Печать этикеток',
                        key: 'print',
                        disabled: selectedRowKeys.length <= 0,
                        icon: <PrinterOutlined />,
                        onClick: () => {
                            if (
                                !hasPermission(userSession.permissions, Permission.ManageWarehouse) &&
                                !hasPermission(userSession.permissions, Permission.PrintDocuments)
                            ) {
                                securityRestriction(api, [Permission.ManageWarehouse, Permission.PrintDocuments]);
                                return;
                            }

                            getPrintData(selectedRowKeys as Array<string>);
                        },
                    },
                ]}
            />
        );
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const renderTable = () => {
        const columns: ColumnsType<IBox> = [
            {
                title: ' ',
                key: 'boxAction',
                align: 'center',
                width: 80,
                render: (_: any, record: IBox) => {
                    return (
                        <Flex gap='small'>
                            {!record.acceptedOn && (
                                <Tooltip placement='topRight' title='Удалить коробку'>
                                    <Button
                                        icon={<DeleteOutlined />}
                                        size={'small'}
                                        onClick={() => {
                                            if (!hasPermission(userSession.permissions, Permission.DeleteRemainingBox)) {
                                                securityRestriction(api, [Permission.DeleteRemainingBox]);
                                                return;
                                            }

                                            modal.confirm({
                                                title: `Удалить коробку "${record.number}"?`,
                                                okType: 'primary',
                                                icon: <QuestionCircleOutlined />,
                                                okText: 'Удалить',
                                                cancelText: 'Отмена',
                                                onOk: () => {
                                                    deleteBoxForm.setFieldValue('id', record.id);

                                                    setOpenDeleteBoxDialog(true);
                                                },
                                            });
                                        }}
                                    />
                                </Tooltip>
                            )}

                            <Tooltip placement='topRight' title='Печать этикетки'>
                                <Button
                                    icon={<PrinterOutlined />}
                                    size={'small'}
                                    onClick={() => {
                                        if (
                                            !hasPermission(userSession.permissions, Permission.ManageWarehouse) &&
                                            !hasPermission(userSession.permissions, Permission.PrintDocuments)
                                        ) {
                                            securityRestriction(api, [Permission.ManageWarehouse, Permission.PrintDocuments]);
                                            return;
                                        }

                                        getPrintData([record.id]);
                                    }}
                                />
                            </Tooltip>
                        </Flex>
                    );
                },
            },
            {
                title: 'Номер',
                dataIndex: 'number',
                width: 200,
                onCell: (record) => ({
                    style: {
                        color: !record.acceptedOn ? 'var(--main-red)' : '',
                    },
                }),
            },
            {
                title: 'Страна',
                render: () => {
                    return boxGroup?.countryName;
                },
                width: 150,
            },
            {
                title: 'Дата приемки',
                align: 'center',
                width: 120,
                render: (_: any, record: IBox) => {
                    return record.acceptedOn && dayjs.utc(record.acceptedOn).local().format('DD.MM.YYYY HH:mm');
                },
            },
            {
                title: 'Комментарий',
                dataIndex: 'comment',
                width: 400,
            },
            {},
        ];

        return (
            <Table
                rowKey='id'
                size='small'
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                columns={columns}
                dataSource={boxes}
                pagination={false}
                scroll={{ y: `calc(100vh - 190px)` }}
                rowSelection={{
                    selectedRowKeys,
                    onChange: onSelectChange,
                    getCheckboxProps: (record) => ({
                        style: { display: !record.acceptedOn ? 'none' : '' },
                    }),
                }}
            />
        );
    };

    return (
        <>
            <Row>
                <Col span={9}>
                    <Row>
                        <FormHeader title={`Партия`} />
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form colon={false} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
                                <Form.Item label='ID'>
                                    <Input disabled value={boxGroup?.consigneeCode} />
                                </Form.Item>
                                <Form.Item label='Маркировка'>
                                    <Input disabled value={boxGroup?.markingCode} />
                                </Form.Item>
                                <Form.Item label='AWB'>
                                    <Input disabled value={boxGroup?.awbNumber} />
                                </Form.Item>
                                <Form.Item label='Комментарий'>
                                    <TextArea disabled rows={4} value={boxGroup?.comment} />
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </Col>
                <Col offset={1} span={14}>
                    <div style={{ marginTop: 10 }}>
                        {renderToolbar()}
                        {renderTable()}
                    </div>
                </Col>
            </Row>

            <Modal
                title='Перемещение коробок'
                open={openTransfer}
                onOk={() => {
                    transferForm.submit();
                }}
                onCancel={() => {
                    transferForm.resetFields();
                    setOpenTransfer(false);
                }}
            >
                <Form
                    colon={false}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    onFinish={onTransfer}
                    form={transferForm}
                    style={{ marginTop: 20 }}
                >
                    <Form.Item name='countryId' hidden>
                        <Input />
                    </Form.Item>
                    <Form.Item name='markingId' hidden>
                        <Input />
                    </Form.Item>
                    <Form.Item name='truckId' hidden>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label='Маркировка'
                        name='newMarkingId'
                        initialValue={boxGroup?.markingId}
                        rules={[{ required: true, message: 'Укажите маркировку' }]}
                    >
                        <Select
                            style={{ width: '100%' }}
                            autoFocus
                            showSearch
                            optionFilterProp='children'
                            filterOption={(input, option) => (option?.label as string).toLowerCase().startsWith(input.toLowerCase())}
                            filterSort={(a, b) => (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())}
                            options={
                                markings &&
                                markings.map((m) => {
                                    return { value: m.id, label: `${m.code} / ${m.cityName}` };
                                })
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        label='Машина'
                        name='newTruckId'
                        initialValue={boxGroup?.truckId}
                    >
                        <Select
                            style={{ width: '100%' }}
                            autoFocus
                            showSearch
                            optionFilterProp='children'
                            filterOption={(input, option) => (option?.label as string).toLowerCase().startsWith(input.toLowerCase())}
                            filterSort={(a, b) => (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())}
                            options={trucks.map((t) => {
                                return { value: t.id, label: `${t.number} - ${dayjs.utc(t.departureOn).local().format('DD.MM.YYYY')}` };
                            })}
                        />
                    </Form.Item>

                    <Form.Item label='Номер AWB' name='newAwbNumber'>
                        <Input />
                    </Form.Item>

                    <Form.Item label='Вес Брутто' name='newGrossWeight'>
                        <InputNumber min={0} decimalSeparator=',' stringMode style={{ width: 150 }} />
                    </Form.Item>

                    <Form.Item label='Объемный вес' name='newVolumeWeight'>
                        <InputNumber min={0} decimalSeparator=',' stringMode style={{ width: 150 }} />
                    </Form.Item>
                </Form>
            </Modal>

            {startPrint && <PrintProcessing />}

            <Modal
                width={400}
                title='Причина удаление коробки'
                open={openDeleteBoxDialog}
                okText='ОК'
                closable={false}
                onOk={() => {
                    deleteBoxForm.submit();
                }}
                onCancel={() => {
                    deleteBoxForm.resetFields();
                    setOpenDeleteBoxDialog(false);
                }}
            >
                <Form colon={false} wrapperCol={{ span: 24 }} form={deleteBoxForm} onFinish={onDeleteBox}>
                    <Form.Item name='id' hidden>
                        <Input />
                    </Form.Item>
                    <Form.Item name='reason' required rules={[{ required: true, message: 'Укажите причину удаления' }]}>
                        <TextArea rows={6} />
                    </Form.Item>
                </Form>
            </Modal>

            {contextHolder}
            {modalContextHolder}
        </>
    );
};

export default Remaining;
